import React, { useRef, useState } from 'react';
import { useStyles } from './styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import FileUploadIcon from '@material-ui/icons/Backup';
import SendIcon from '@material-ui/icons/Send';
import { IconButton } from '@material-ui/core';
import EmojiPicker from '../EmojiPicker/index';
import ImagesPreview from '../ImagesPreview/index';
import { errorService } from 'services/alert/services';
import { fileToBase64 } from 'utils/fileToBase64';

const TextArea = ({ onEnter, onEnterImages }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [base64Images, setBase64Images] = useState([]);
  const [files, setFiles] = useState([]);

  const uploadImageInputRef = useRef(null);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleEnter = async () => {
    try {
      if (files.length) {
        setLoading(true);
        setShowPicker(false);
        setShowImages(false);
        await onEnterImages(files, value);
      } else if (!value.trim() || isLoading) {
        return;
      } else {
        setLoading(true);
        setShowPicker(false);
        setShowImages(false);
        await onEnter(value);
      }
    } finally {
      setValue('');
      setLoading(false);
      resetFiles();
    }
  };

  const onEmojiPick = (emoji) => {
    setValue((prev) => prev + emoji);
  };

  // const onImageUpload = async (e) => {
  //   const files = e.currentTarget?.files;
  //   if (!files) return;
  //   try {
  //     const promises = [];
  //     for (const file of files) {
  //       promises.push(fileToBase64(file));
  //     }
  //     const awaitedPromises = await Promise.all(promises);
  //     setBase64Images((prev) => [...prev, ...awaitedPromises]);
  //     setFiles((prev) => [...prev, ...files]);
  //     setShowPicker(false);
  //     setShowImages(true);
  //   } catch (e) {
  //     errorService.sendError('Error');
  //   }
  // };

  const onImageDelete = (index) => {
    const filteredFiles = files.filter((_, i) => i !== index);
    setBase64Images((prev) => prev.filter((_, i) => i !== index));
    setFiles(filteredFiles);

    if (filteredFiles.length === 0) setShowImages(false);
  };

  // const onFileUploadIconClick = () => {
  //   uploadImageInputRef.current.click();
  // };

  const resetFiles = () => {
    setFiles([]);
    setBase64Images([]);
  };

  const onEmojiIconClick = () => {
    resetFiles();
    setShowImages(false);
    setShowPicker((prev) => !prev);
  };

  return (
    <div className={classes.container}>
      <input
        className={classes.uploadImageInput}
        type="file"
        multiple
        ref={uploadImageInputRef}
        // onChange={onImageUpload}
        accept="image/png, image/gif, image/jpeg"
        disabled
      />
      <div
        className={classes.emojiPickerWrapper}
        style={{ display: showPicker ? 'block' : 'none' }}
      >
        {showPicker && <EmojiPicker onPick={onEmojiPick} />}
      </div>
      <div
        className={classes.imagesPreviewWrapper}
        style={{ display: showImages ? 'block' : 'none' }}
      >
        {showImages && (
          <ImagesPreview
            images={base64Images}
            onClose={() => setShowImages(false)}
            onImageDelete={onImageDelete}
          />
        )}
      </div>
      <div className={classes.icons}>
        <IconButton className={classes.iconButton} disabled>
          <FileUploadIcon />
        </IconButton>
        <IconButton className={classes.iconButton} onClick={onEmojiIconClick}>
          <EmojiEmotionsIcon />
        </IconButton>
      </div>
      <TextField
        placeholder="Текст"
        className={classes.textArea}
        value={value}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleEnter();
          }
        }}
      />
      <Button
        color="primary"
        variant="contained"
        disabled={isLoading}
        onClick={handleEnter}
        className={classes.button}
      >
        <SendIcon />
      </Button>
    </div>
  );
};

export default TextArea;
