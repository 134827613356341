import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ColumnDef,
} from 'material-react-table';
import qs from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import DoneIcon from '@mui/icons-material/Done';

import GridContainer from 'components/Grid/GridContainer.js';
import CardBody from 'components/Card/CardBody.js';
import { URLHelper } from 'shared/helpers/URLHelper';
import { useDebounce } from 'hooks/use-debounce';
import { PaymentService } from 'services/api/payment';
import { errorService } from 'services/alert/services';
import { Nullable } from 'shared/types';
import { StatisticsService } from 'services/api/statistics';

const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300' as any,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  balance: {
    marginRight: '10px',
    fontWeight: 'bold' as any,
  },
  statsDetailWrapper: {
    width: '100%',
    display: "flex",
    paddingLeft: '10%',
    flexWrap: "wrap" as const,
  },
  statsDetailItem: {
    margin: "0 15px"
  },

  detailPanelTitle: {
    margin: "5px 0",
    fontWeight: 700,
  },
  detailPanelOption: {
    fontWeight: 600,
  }
};

const useStyles = makeStyles(styles);

export const convertIntoArray = (values: Record<string, any>) => {
  const convertedArray = Object.entries(values).map(([key, value]) => ({
    text: value.replace(/([A-Z])/g, ' $1').trim(),
    value: key,
  }));
  return convertedArray;
};

const validateFilter = (filter) => {
  if (Array.isArray(filter) && !filter[0] && !filter[1]) {
    return false;
  }
  return true;
};

export const getFilters = (columnFilters) => {
  return columnFilters.reduce((acc, item) => {
    if (validateFilter(item.value)) acc[item.id] = item.value;
    return acc;
  }, {});
};

const MIN_PAGE_SIZE = 30;

const externalProviderType = {
  1: 'Dicechess',
  2: 'AlgNet',
  3: 'Evenbet',
};

const externalGameType = {
  1: "Dice Chess",
  2: "Slots",
  3: "Live Dealer",
  4: "Poker",
  5: "Sports Betting",
  6: "Virtual Sport",
};

const UsersStatistics = () => {
  const params = useParams();

  const { userId } = params;
  const location = useLocation();
  const classes = useStyles();

  const searchQueryFilters = qs.parse(location.search.substring(1));

  const columns = useMemo(
    (): MRT_ColumnDef<any>[] => [
      {
        header: 'ID',
        accessorKey: '_id',
        enableColumnFilterModes: false,
        enableColumnFilter: false,
      },
      {
        header: 'Пользователь',
        accessorKey: 'user',
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          // @ts-ignore
          return cell.getValue()?.email;
        },
      },
      {
        header: 'Общяя сумма ставок',
        accessorKey: 'totalCredit',
        enableColumnFilterModes: false,
        enableColumnFilter: false,
      },
      {
        header: 'Общяя сумма выигрышей',
        accessorKey: 'totalDebit',
        enableColumnFilterModes: false,
        enableColumnFilter: false,
      },
      {
        header: 'Общий результат',
        accessorKey: 'profit',
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return row.getValue<number>("totalCredit") - row.getValue<number>("totalDebit");
        },
      },
    ],
    [],
  );

  const defaultFilters = useMemo(() => {
    return columns.reduce((acc, column) => {
      const searchQueryFilter = searchQueryFilters[column.accessorKey as string];
      if (searchQueryFilter) {
        acc.push({
          id: column.accessorKey,
          value: searchQueryFilter,
        });
      }
      return acc;
    }, [] as any[]);
  }, [searchQueryFilters]);

  const isPersonalPayments = userId && userId !== '0';

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState(defaultFilters);
  const [sorting, setSorting] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: MIN_PAGE_SIZE,
  });

  const debouncedColumnFilters = useDebounce(columnFilters, 700);


  const getStats = async () => {
    try {
      setIsLoading(true);

      const sort = sorting[0];
      const orderQuery = sort
        ? { orderBy: sort?.id, orderDirection: sort?.desc ? 'desc' : 'asc' }
        : {};

      const filterByUserId = isPersonalPayments ? { userId } : {};
      const filters = getFilters(columnFilters);

      URLHelper.replaceSearchParams(
        qs.stringify({ ...filters, page: pagination.pageIndex, pageSize: pagination.pageSize }),
      );

      const { data: remoteData } = await StatisticsService.findAll({
        ...orderQuery,
        ...filters,
        ...filterByUserId,
      });
      setData(remoteData.stats);
      setRowCount(remoteData.stats.length);
      setIsError(false);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, [debouncedColumnFilters, pagination.pageIndex, pagination.pageSize, sorting]);


  return (
    <div>
      <GridContainer>
        {/* @ts-ignore */}
        <CardBody style={{ overflow: 'hidden' }}>
          <MaterialReactTable
            // @ts-ignore
            columns={columns}
            data={data}
            getRowId={(row: any) => row._id}
            initialState={{
              showColumnFilters: true,
            }}
            enableMultiSort={false}
            manualFiltering
            manualPagination
            manualSorting
            enableRowActions
            enableGlobalFilter={false}
            muiToolbarAlertBannerProps={
              isError
                ? {
                    color: 'error',
                    children: 'Error loading data',
                  }
                : undefined
            }
            onColumnFiltersChange={setColumnFilters}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            rowCount={rowCount}
            enableColumnFilterModes
            localization={MRT_Localization_RU}
            enableToolbarInternalActions
            state={{
              columnFilters,
              isLoading,
              pagination,
              showAlertBanner: isError,
              sorting,
            }}
            renderDetailPanel={({ row }) => {
              const providers = row.original.providers;
              const gameTypes = row.original.gameTypes;

              return (
                <Box className={classes.statsDetailWrapper}>
                  <div className={classes.statsDetailItem}>
                    <h5 className={classes.detailPanelTitle}>Статистика по провайдерам</h5>
                    <div>
                      {Object.entries(providers).map(([providerId, value]: [string, any]) => {
                        return (
                          <div>
                            <b className={classes.detailPanelOption}>{externalProviderType[providerId] ?? "?"}</b>
                            <div>
                              <span>Ставки: {value.credit.toFixed(2)}$</span> <br/>
                              <span>Выигрыши: {value.debit.toFixed(2)}$</span> <br/>
                              <span>Общий результат: {(value.credit - value.debit).toFixed(2)}$</span>
                            </div>  
                          </div>
                        )
                      })}  
                    </div>                  
                  </div>
                  <div>
                    <h5 className={classes.detailPanelTitle}>Статистика по играм</h5>
                    <div>
                      {Object.entries(gameTypes).map(([gameId, value]: [string, any]) => {
                        return (
                          <div>
                            <b className={classes.detailPanelOption}>{externalGameType[gameId] ?? "?"}</b>
                            <div>
                              <span>Ставки: {value.credit.toFixed(2)}$</span> <br/>
                              <span>Выигрыши: {value.debit.toFixed(2)}$</span> <br/>
                              <span>Общий результат: {(value.credit - value.debit).toFixed(2)}$</span>
                            </div>  
                          </div>
                        )
                      })}  
                    </div>                  
                  </div>
                </Box>
              )
            }}
            renderTopToolbar={({ table }) => {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box>
                    <MRT_ShowHideColumnsButton table={table} />
                    <MRT_FullScreenToggleButton table={table} />
                    <MRT_ToggleDensePaddingButton table={table} />
                    <MRT_ToggleFiltersButton table={table} />
                  </Box>
                </Box>
              );
            }}
          />
        </CardBody>
      </GridContainer>
    </div>
  );
};

export default UsersStatistics;
