import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { emitter } from 'shared/emitter';
import { UserService } from 'services/api/user';
import { errorService } from 'services/alert/services';
import RemoveRedEyeIcon from 'components/Icons/RemoveRedEyeIcon';

import { mapSecurityStatus } from '../UsersTable/index';
import { UserRejectVerificationDialog } from '../UserRejectVerification/index';
import { rejectionReasonsLabels } from '../UserRejectVerification/constant';
import { shortenString } from '../../utils/shortenString';

import { UpdateBlockedGames } from './components/UpdateBlockedGames';
import { useStyles } from './styles';

const documentTypeLabels = {
  passport: 'Passport',
  internationalPassport: 'International Passport',
  id: 'ID',
  driveLicense: 'Drive License',
};

const mapDocumentType = (type) => {
  return documentTypeLabels[type] || type;
};

const VerificationItem = ({ verificationInfo, onVerificationPhotoFullScreen }) => {
  const classes = useStyles();

  return (
    <div className={classes.verificationInfoContainer}>
      {verificationInfo?.photoUrl && (
        <div className={classes.verificationPhotoHolder}>
          <div className={classes.verificationPhotoContainer}>
            <div className={classes.verificationPhotoWrapper}>
              <div
                className={classes.imageShadow}
                onClick={() => onVerificationPhotoFullScreen(verificationInfo?.photoUrl)}
              >
                <RemoveRedEyeIcon className={classes.openFullScreenIcon} />
              </div>
              <img src={verificationInfo?.photoUrl} className={classes.verificationInfoPhoto} />
            </div>
          </div>
        </div>
      )}
      <div className={classes.verificationInfoList}>
        {verificationInfo?.documentType && (
          <Grid item xs={12}>
            <span className={classes.bold}>Вид документа:</span>{' '}
            {mapDocumentType(verificationInfo?.documentType) || ''}
          </Grid>
        )}
        {verificationInfo?.documentId && (
          <Grid item xs={12}>
            <span className={classes.bold}>Номер документа:</span> {verificationInfo?.documentId}
          </Grid>
        )}
        {verificationInfo?.fullname && (
          <Grid item xs={12}>
            <span className={classes.bold}>Имя и фамилия:</span> {verificationInfo?.fullname}
          </Grid>
        )}
        {verificationInfo?.rejectReason && (
          <Grid item xs={12}>
            <span className={classes.bold}>Причина отказа:</span>{' '}
            {rejectionReasonsLabels[verificationInfo.rejectReason] ?? verificationInfo.rejectReason}
          </Grid>
        )}
      </div>
    </div>
  );
};

const ChipSetting = ({ enabled, text }) => {
  return enabled ? (
    <Chip icon={<DoneIcon />} label={text} color="primary" />
  ) : (
    <Chip icon={<ClearIcon />} label={text} />
  );
};

export const UserProfile = ({ profile, onVerificationUpdate, handleClose }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [openBlockedExternalGames, setOpenBlockedExternalGames] = useState(false);

  const onVerificationPhotoFullScreen = (photoUrl) => {
    emitter.emit('fullscreen.image.open', photoUrl);
  };

  const verifyUser = async () => {
    setLoading(true);
    try {
      await UserService.update(profile._id, { verified: true });
      if (onVerificationUpdate) onVerificationUpdate();
      if (handleClose) handleClose();
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const rejectUserVerification = async () => {
    setLoading(true);
    try {
      if (onVerificationUpdate) onVerificationUpdate();
      if (handleClose) handleClose();
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getLength = () => {
    if (window.innerWidth > 900) {
      return 35;
    } else if (window.innerWidth >= 650 && window.innerHeight <= 900) {
      return 25;
    } else {
      return 15;
    }
  };

  const hasDocuments = Boolean(profile?.verificationInfo?.documentType);
  const hasPhoto = Boolean(profile?.verificationInfo?.photoUrl);

  return (
    <Grid container>
      <UserRejectVerificationDialog
        id={profile?._id}
        open={openVerificationDialog}
        onClose={() => {
          setOpenVerificationDialog(false);
        }}
        onSubmit={rejectUserVerification}
      />
      <UpdateBlockedGames
        id={profile?._id}
        profile={profile}
        open={openBlockedExternalGames}
        onClose={() => {
          setOpenBlockedExternalGames(false);
        }}
      />
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12}>
            <span className={classes.bold}>ID:</span> {profile?._id ?? '...'}
          </Grid>
          <Grid item xs={12}>
            <span className={classes.bold}>Имя пользователя:</span> {profile?.nickname ?? '...'}
          </Grid>

          <Grid item xs={12}>
            <span className={classes.bold}>Почтовый ящик:</span> {profile?.email ?? '...'}
          </Grid>
          {profile?.balance && (
            <>
              <Grid item xs={12}>
                <span className={classes.bold}>Основной баланс:</span> {profile.balance?.play ?? 0}
              </Grid>
              <Grid item xs={12}>
                <span className={classes.bold}>Бонусный баланс:</span> {profile.balance?.bonus ?? 0}
              </Grid>
              {Boolean(profile.balance?.referralRevenueShare) && (
                <Grid item xs={12}>
                  <span className={classes.bold}>Доход реферала:</span>{' '}
                  {profile.balance?.referralRevenueShare}
                </Grid>
              )}
            </>
          )}

          {profile?.stats && profile?.stats?.games && (
            <>
              <Grid item xs={12}>
                <span className={classes.bold}>Побед:</span> {profile.stats.games.won}
              </Grid>

              <Grid item xs={12}>
                <span className={classes.bold}>Поражений:</span> {profile.stats.games.lost}
              </Grid>

              <Grid item xs={12}>
                <span className={classes.bold}>Всего игр:</span> {profile.stats.games.played}
              </Grid>

              <Grid item xs={12}>
                <span className={classes.bold}>Профит:</span> {profile.stats.profit}
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <span className={classes.bold}>Дата регистрации</span>:{' '}
            {profile?.registered
              ? moment(profile.registered).format('HH:MM:ss DD-MM-YYYY')
              : 'HH:MM:ss DD-MM-YYYY'}
          </Grid>

          <Grid item xs={12}>
            <span className={classes.bold}>Верифицирован:</span>:{' '}
            {profile?.verificationInfo?.verified ? 'Да' : 'Нет'}
          </Grid>

          {profile?.gameSettings && (
            <Grid item xs={12}>
              <span className={classes.bold}>Настройки:</span>
              <ul>
                <li className={classes.padding}>
                  <ChipSetting enabled={profile.gameSettings.autoDice} text="Автобросок" />
                </li>
                <li className={classes.padding}>
                  <ChipSetting enabled={profile.gameSettings.diceSound} text="Звук кубиков" />
                </li>
                <li className={classes.padding}>
                  <ChipSetting enabled={profile.gameSettings.pieceSound} text="Звук хода" />
                </li>
                <li className={classes.padding}>
                  <ChipSetting
                    enabled={profile.gameSettings.highlightMoves}
                    text="Подсветка хода"
                  />
                </li>
                <li className={classes.padding}>
                  <ChipSetting enabled={profile.gameSettings.digitDice} text="Цифры вместо фигур" />
                </li>
              </ul>
            </Grid>
          )}
          {profile?.comments && (
            <Grid item xs={12}>
              <span className={classes.bold}>
                Статус безопасности: {mapSecurityStatus(profile?.security?.status)}
              </span>
              <ul>
                {profile?.comments?.length ? (
                  <>
                    {profile?.comments.map((comment, i) => (
                      <li key={i} className={classes.padding}>
                        {comment}
                      </li>
                    ))}
                  </>
                ) : (
                  <span>Нету комментариев</span>
                )}
              </ul>
            </Grid>
          )}
          {profile?.nicknameHistory && (
            <Grid item xs={12}>
              <p className={classes.bold}>История смены ников:</p>
              <ul>
                {profile?.nicknameHistory.length ? (
                  <>
                    {profile?.nicknameHistory.map((nickname, i) => (
                      <li key={i} className={classes.padding}>
                        {nickname.nickname} -{' '}
                        {moment(nickname.changedAt).format('HH.mm, DD.MM.YYYY')}
                      </li>
                    ))}
                  </>
                ) : (
                  <span>Не было смены ников</span>
                )}
              </ul>
            </Grid>
          )}
          {profile?.externalGameStats && (
            <>
              <Grid item xs={12}>
                <p className={classes.bold}>Покер:</p>
                <ul className={classes.list}>
                  <span>Заведено: {profile?.externalGameStats?.poker?.putOnTable?.total ?? 0}</span>
                  <span>
                    Выведено: {profile?.externalGameStats?.poker?.getFromTable?.total ?? 0}
                  </span>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <p className={classes.bold}>Слоты:</p>
                <ul className={classes.list}>
                  <span>Проиграно: {profile?.externalGameStats?.slots?.lost?.total ?? 0}</span>
                  <span>Выиграно: {profile?.externalGameStats?.slots?.won?.total ?? 0}</span>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <p className={classes.bold}>Live Dealer:</p>
                <ul className={classes.list}>
                  <span>Проиграно: {profile?.externalGameStats?.liveDealer?.lost?.total ?? 0}</span>
                  <span>Выиграно: {profile?.externalGameStats?.liveDealer?.won?.total ?? 0}</span>
                </ul>
              </Grid>
            </>
          )}

          <VerificationItem
            verificationInfo={profile?.verificationInfo}
            onVerificationPhotoFullScreen={onVerificationPhotoFullScreen}
          />

          <div className={classes.verificationActions}>
            {!profile?.verificationInfo?.verified && (
              <Button color="primary" variant="contained" onClick={verifyUser} disabled={isLoading}>
                Верифицировать
              </Button>
            )}
            {(hasDocuments || hasPhoto || profile?.verificationInfo?.verified) && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenVerificationDialog(true)}
                disabled={isLoading}
              >
                Отклонить верификацию
              </Button>
            )}
          </div>

          {_.reverse(profile?.verificationHistory)?.map((verificationInfo, i) => (
            <Accordion style={{ width: '100%' }} key={i} className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionSummary}
              >
                <Typography style={{ marginRight: 10 }} className={classes.accordionHeading}>
                  {shortenString(
                    rejectionReasonsLabels[verificationInfo.rejectReason] ??
                      verificationInfo.rejectReason,
                    getLength(),
                  )}
                </Typography>
                <Typography className={classes.accordionSecondaryHeading}>
                  {moment(verificationInfo.createdAt).format('DD-MM-YYYY')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <VerificationItem
                  verificationInfo={verificationInfo}
                  onVerificationPhotoFullScreen={onVerificationPhotoFullScreen}
                />
              </AccordionDetails>
            </Accordion>
          ))}
          <Grid item xs={12}>
            <div className={classes.buttonWrapper}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenBlockedExternalGames(true)}
                disabled={isLoading}
                startIcon={<SportsEsportsIcon />}
              >
                Заблокировать игры
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Avatar
          style={{ width: 64, height: 64 }}
          alt={profile?.nickname?.toUpperCase()}
          src="/static/images/avatar/1.jpg"
        />
      </Grid>
    </Grid>
  );
};
export const UserProfileDialog = ({ onVerificationUpdate, id, ...props }) => {
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (props.open && id) {
      getUserProfile(id);
    }
  }, [props.open, id]);

  const getUserProfile = async (id) => {
    try {
      const { data } = await UserService.getUserProfile(id);
      setProfile(data);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const handleClose = (data) => {
    props.onHandleClose(data);
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Профиль пользователя</DialogTitle>
        <DialogContent>
          <UserProfile
            profile={profile}
            onVerificationUpdate={onVerificationUpdate}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              window.open(profile?.impersonateUrl, '_blank');
            }}
          >
            Войти как пользователь
          </Button>
          <Button color="primary" onClick={() => handleClose(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UserProfileDialog.propTypes = {
  profile: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onHandleClose: PropTypes.func.isRequired,
};
