import React, { useState, useEffect, useContext, createContext } from 'react';

import { useAPI } from 'hooks/use-api';
import endpoints from 'api/endpoints';
import { ReferralProgramService } from 'services/api/referral-program';
import { errorService } from 'services/alert/services';
import { useAuth } from 'hooks/use-auth';
import { ExternalGameService } from 'services/api/external-game';
import { ExternalGameProviderService } from 'services/api/external-game-provider';

const storeContext = createContext<any>(null);

export function ProvideStore({ children }) {
  const store = useProvideStore();
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
}

export const useStore = () => {
  return useContext(storeContext);
};

const useProvideStore = () => {
  const [rankList, setRankList] = useState<any[]>([]);
  const [referralPrograms, setReferralPrograms] = useState<any[]>([]);
  const [externalGames, setExternalGames] = useState<any[]>([]);
  const [externalGameProviders, setExternalGameProviders] = useState<any[]>([]);

  const auth = useAuth();

  const [{ data }, doGetRanks] = useAPI();

  useEffect(() => {
    if (data) setRankList(data);
  }, [data]);

  useEffect(() => {
    if (auth?.user) {
      getReferralPrograms();
      getExternalGames();
      getExternalGameProviders();
    }
  }, [auth?.user]);

  const getReferralPrograms = async () => {
    try {
      const { data } = await ReferralProgramService.getAll();
      setReferralPrograms(data.referralPrograms);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const getExternalGames = async () => {
    try {
      const { data } = await ExternalGameService.findAll();
      setExternalGames(data.data);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const getExternalGameProviders = async () => {
    try {
      const { data } = await ExternalGameProviderService.findAll();
      setExternalGameProviders(data);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  useEffect(() => {
    doGetRanks(endpoints.ranks);
  }, []);

  return {
    ranks: rankList,
    referralPrograms,
    externalGames,
    externalGameProviders,
  };
};
