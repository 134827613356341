import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import cn from 'classnames';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { errorService } from 'services/alert/services';
import { SystemAvatarService } from 'services/api/system-avatar';

import { FileService } from '../../services/api/file';

import { dialogStyles } from './styles';

const CreateOrUpdateGameSystemAvatar = ({ open, handleClose, mode, data, onSuccess }) => {
  const styles = dialogStyles();

  const handleSubmit = async (values) => {
    try {
      if (values.file) {
        const formData = new FormData();
        formData.append('file', values.file as Blob);
        formData.append('name', values.url);
        await FileService.uploadSystemAvatar(formData);
      }
      if (mode === 'create') {
        await SystemAvatarService.create({
          url: values.url,
        });
      } else {
        await SystemAvatarService.update({
          _id: data._id,
          url: values.url,
        });
      }
      await onSuccess();
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      handleClose();
    }
  };

  const initialValues = useMemo(() => {
    if (mode === 'create') {
      return {
        url: '',
        file: null,
      };
    }
    return {
      url: data.url,
      file: null,
    };
  }, [mode, data]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle>{mode === 'create' ? 'Создать' : 'Обновить'} аватар</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              url: Yup.string().required('Введите название файла'),
            })}
            validateOnChange
            onSubmit={handleSubmit}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              values,
              errors,
              setFieldValue,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.url && touched.url)}
                      name="url"
                      margin="dense"
                      label="Введите название файла"
                      value={values.url}
                      placeholder="Введите название файла"
                    />
                    <div className={styles.errorText}>
                      {touched.url ? (errors.url as string) : ''}
                    </div>
                  </FormControl>
                  <FormControl className={cn(styles.formControl, styles.fileInput)} size="small">
                    <input
                      type="file"
                      onChange={(e) =>
                        setFieldValue('file', e.target?.files?.length ? e.target.files[0] : null)
                      }
                    />
                  </FormControl>
                  <DialogActions>
                    <Button color="primary" variant="contained" type="submit">
                      {mode === 'create' ? 'Создать' : 'Обновить'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateOrUpdateGameSystemAvatar;
