import React, { useEffect, useRef, useState } from 'react';
// import moment from 'moment';

import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';
import MaterialTable from 'material-table';

import { TournamentService } from 'services/api/tournament';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { errorService, infoService } from 'services/alert/services';

import CreateTournamentDialog from './create-or-update-tournament-modal';
import CancelTournamentDialog from './cancel-tournament-dialog';
import { tournamentType, isType, modes } from './utils';
import { ETournamentType } from 'domain/tournament';
import { ETournamentStatus } from 'domain/tournament/types';

const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const mapStatus = (status) => {
  switch (status) {
    case ETournamentStatus.REGISTERING:
      return 'Регистрация';
    case ETournamentStatus.COMPLETED:
      return 'Заполнена';
    case ETournamentStatus.RUNNING:
      return 'В процессе';
    case ETournamentStatus.FINISHED:
      return 'Завершено';
    case ETournamentStatus.CANCELLED:
      return 'Отменено';
    default:
      return status;
  }
};

const mapTimeControl = (time) => {
  switch (time) {
    case 1:
      return '1 + 1';
    case 3:
      return '3 + 3';
    case 5:
      return '5 + 5';
    case 10:
      return '10 + 0';
    case 20:
      return '20 + 0';
  }
};

const mapBalanceType = (type) => {
  switch (type) {
    case 'coins':
      return 'Dicechess Coins';
    case 'play':
      return 'Доллар';
    case 'referralBonus':
      return 'Бонус';
    default:
      return type;
  }
};
// @ts-ignore
const useStyles = makeStyles(styles);

const Tournaments = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [openCreateTournamentModal, setOpenCreateTournamentModal] = useState(false);
  const [selectedTournamentId, setSelectedTournamentId] = useState(null);
  const [openUpdateTournamentModal, setOpenUpdateTournamentModal] = useState(false);
  const [openCancelTournamentDialog, setOpenCancelTournamentDialog] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState<any>(null);

  const tableRef = useRef();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await TournamentService.findAll();
      setData(response.data.tournaments);
    } catch (e) {
      errorService.sendError('Произошла ошибка' + e.message);
    }
  };

  const handleCreateModalClose = () => {
    setOpenCreateTournamentModal(false);
  };

  const handleUpdateModalClose = () => {
    setOpenUpdateTournamentModal(false);
    setSelectedTournament(null);
  };

  const onRowDelete = async (oldData) => {
    try {
      await TournamentService.deleteByID(oldData._id);
      // @ts-ignore
      setData((tournaments) => tournaments.filter((t) => t._id !== oldData._id));
    } catch (e) {
      errorService.sendError('Произошла ошибка' + e.message);
    }
  };

  const onCreate = async (values) => {
    const body = {
      ...values,
      type: values.type,
      prizes: values.prizes.map((prize) => ({ ...prize, amount: +prize.amount })),
      buyIn: +values.buyIn,
      maxPlayers: +values.maxPlayers,
      startAt: new Date(values.startAt),
      registrationAt: values.registrationAt
      ? new Date(values.registrationAt)
      : null,
      gameSettings: {
        fenType: 1,
        matchCount: values.matchCount,
        time: values?.time,
      },
      entryRequirements: {
        
      }
    };
    try {
      await TournamentService.create(body);
      await fetchData();
      handleCreateModalClose();
    } catch (e) {
      errorService.sendError(e?.message);
    }
  };

  const onUpdate = async (newValues, prevValues) => {
    const body = {
      ...newValues,
      type: newValues.type,
      prizes: newValues.prizes.map((prize) => ({ ...prize, amount: +prize.amount })),
      buyIn: +newValues.buyIn,
      maxPlayers: +newValues.maxPlayers,
      startAt: new Date(newValues.startAt),
      registrationAt: newValues.registrationAt
      ? new Date(newValues.registrationAt)
      : null,
      gameSettings: {
        fenType: 1,
        matchCount: newValues.matchCount,
        time: newValues?.time,
      },
      entryRequirements: {
        
      }
    };
    try {
      await TournamentService.updateByID((selectedTournament as any)?._id, body);
      handleUpdateModalClose();
      fetchData();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  return (
    <div>
      {openCreateTournamentModal && (
        // @ts-ignore
        <CreateTournamentDialog
          open={true}
          handleClose={handleCreateModalClose}
          onSubmit={onCreate}
          mode={modes.create}
        />
      )}
      {openUpdateTournamentModal && (
        <CreateTournamentDialog
          open={true}
          handleClose={handleUpdateModalClose}
          onSubmit={onUpdate}
          tournamentId={selectedTournament?._id}
          mode={modes.update}
        />
      )}
      {openCancelTournamentDialog && (
        <CancelTournamentDialog
          open={true}
          id={selectedTournamentId}
          onHandleClose={() => {
            setOpenCancelTournamentDialog(false);
            setSelectedTournamentId(null);
          }}
          onSuccess={async () => {
            infoService.sendInfo('Турнир отменен');
            await fetchData();
          }}
        />
      )}
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление турнирами</p>
            </CardHeader>
            <CardBody>
              {/* @ts-ignore */}
              <MaterialTable
                title="Турниры"
                tableRef={tableRef}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Создать турнир',
                    isFreeAction: true,
                    onClick: () => setOpenCreateTournamentModal(true),
                  },
                  {
                    icon: 'edit',
                    tooltip: 'Обновить турнир',
                    onClick: (_, rowData) => {
                      setSelectedTournament(rowData);
                      setOpenUpdateTournamentModal(true);
                    },
                  },
                  {
                    icon: 'cancel',
                    tooltip: 'Отменить турнир',
                    onClick: (_, rowData) => {
                      setSelectedTournamentId(rowData._id);
                      setOpenCancelTournamentDialog(true);
                    },
                  },
                ]}
                localization={{
                  body: {
                    deleteTooltip: 'Удалить',
                    editRow: {
                      deleteText: 'Вы уверены что хотите удалить турнир?',
                    },
                  },
                  toolbar: { searchPlaceholder: 'Поиск' },
                  pagination: { labelRowsSelect: 'cтрок' },
                  header: {
                    actions: 'Действия',
                  },
                }}
                columns={[
                  {
                    title: 'ID',
                    field: '_id',
                    editable: false,
                  },
                  {
                    title: 'Название',
                    field: 'title',
                  },
                  {
                    title: 'Описание',
                    field: 'description',
                  },
                  {
                    title: 'Дата начала',
                    field: 'startDate',
                    type: 'datetime',

                    render: ({ startDate }) => (
                      <div>
                        {moment.tz(startDate, moment.tz.guess()).format('HH:mm DD-MM-YYYY')}
                      </div>
                    ),
                  },
                  {
                    title: 'Приз',
                    field: 'prize',
                    type: 'numeric',
                    editable: false,
                  },
                  {
                    title: 'Buy in',
                    field: 'buyIn',
                    type: 'numeric',
                  },
                  {
                    title: 'Количество подкл. игроков',
                    field: 'joinedPlayers',
                    type: 'numeric',
                    editable: false,
                  },
                  {
                    title: 'Макс. количество игроков',
                    field: 'maxPlayers',
                    type: 'numeric',
                    editable: false,
                  },
                  {
                    title: 'Статус',
                    field: 'status',
                    editable: false,

                    render: ({ status }) => <div>{mapStatus(status)}</div>,
                  },
                ]}
                data={data}
                options={{
                  filtering: true,
                  // debounceInterval: 700,
                  search: false,
                }}
                editable={{
                  isEditable: () => true,
                  onRowAddCancelled: () => console.log('Row adding cancelled'),
                  onRowUpdateCancelled: () => console.log('Row editing cancelled'),
                  onRowDelete,
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Tournaments;
