import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core';

import { errorService, infoService, warnService } from 'services/alert/services';
import { ETransactionTarget, ETransactionType } from 'domain/transaction';

import endpoints from '../../api/endpoints';
import { usePostAPI } from '../../hooks/use-post-api';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

export default function CreateTransaction(props) {
  const [open, setOpen] = useState(false);

  const [
    {
      data: createTransactionData,
      isLoading: isCreateTransactionLoading,
      isError: isCreateTransactionError,
    },
    doCreateTransaction,
  ] = usePostAPI({});

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = (data) => {
    props.onHandleClose(data);
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Создать транзакцию для пользователя</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              amount: 0,
              type: String(ETransactionType.DEBIT),
              userId: props.userId,
              target: String(ETransactionTarget.PLAY),
            }}
            validationSchema={Yup.object().shape({
              amount: Yup.number().min(0.01, 'Минимальная сумма 0.01').required('Введите Сумму'),
            })}
            onSubmit={async ({ amount, type, userId, target }) => {
              try {
                await new Promise((resolve, reject) => {
                  doCreateTransaction({
                    url: endpoints.transactions,
                    params: {
                      userId,
                      type: +type,
                      amount: parseFloat(String(amount)),
                      target: +target,
                    },
                    onSuccess: (data) => {
                      infoService.sendInfo('Транзакция была успешно создана');
                      resolve(data);
                      handleClose(data);
                    },
                    onError: () => {
                      reject();
                    },
                  });
                });
              } catch (e) {
                errorService.sendError('Не возможно создать транзакцию');
              }
            }}
            render={({ values, errors, handleSubmit, handleChange, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl component="fieldset" fullWidth>
                    <div style={{ color: 'red', fontSize: 12 }}>{errors.amount}</div>
                    <Typography variant={'body1'}>Тип баланса</Typography>
                    <RadioGroup name="target" value={values.target} onChange={handleChange}>
                      <FormControlLabel
                        value={String(ETransactionTarget.COINS)}
                        control={<Radio />}
                        label="Монетки"
                      />
                      <FormControlLabel
                        value={String(ETransactionTarget.PLAY)}
                        control={<Radio />}
                        label="Доллар"
                      />
                      <FormControlLabel
                        value={String(ETransactionTarget.REFERRAL_BONUS)}
                        control={<Radio />}
                        label="Бонус"
                      />
                    </RadioGroup>
                    <br />
                    <Typography variant={'body1'}>Тип транзакции</Typography>
                    <RadioGroup name="type" value={values.type} onChange={handleChange}>
                      <FormControlLabel
                        value={String(ETransactionType.CREDIT)}
                        control={<Radio />}
                        label="Кредит"
                      />
                      <FormControlLabel
                        value={String(ETransactionType.DEBIT)}
                        control={<Radio />}
                        label="Дебет"
                      />
                      <FormControlLabel
                        value={String(ETransactionType.DEPOSIT)}
                        control={<Radio />}
                        label="Платеж"
                      />
                      <FormControlLabel
                        value={String(ETransactionType.WITHDRAWAL)}
                        control={<Radio />}
                        label="Выплата"
                      />
                      {/*  <FormControlLabel value={'10'} control={<Radio />} label="Отмена выплаты" />
                      <FormControlLabel value={'11'} control={<Radio />} label="Резерв выплаты" />
                      <FormControlLabel value={'12'} control={<Radio />} label="Возврат платежа" />*/}
                    </RadioGroup>
                    <br />
                    <TextField
                      onChange={handleChange}
                      error={!!errors.amount}
                      name="amount"
                      autoFocus
                      label="Сумма"
                      value={values.amount}
                      placeholder="Введите число"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </FormControl>

                  <DialogActions>
                    <Button onClick={() => handleClose(null)} color="secondary" variant="contained">
                      Отмена
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                    >
                      Создать
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

CreateTransaction.propTypes = {
  userId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onHandleClose: PropTypes.func.isRequired,
};
