import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment-timezone';

import cn from 'classnames';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import MessageItem from './components/MessageItem/index';

import { useAuthAPI } from 'hooks/use-auth-api';
import { errorService } from 'services/alert/services';
import endpoints from 'api/endpoints';
import { useParams } from 'react-router-dom';
import { ChatService } from 'services/api/chat';
import { useStyles } from './styles';
import TextArea from './components/TextArea';

export default function Support() {
  const classes = useStyles();
  const request = useAuthAPI();
  const [user, setUser] = useState<{_id: string, nickname: string, email: string} | null>(null)
  const [messages, setMessages] = useState([]);
  const [pageSize, setPageSize] = useState(500);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const messagesScroller = useRef(null);

  const params = useParams();

  useEffect(() => {
    getMessages();
  }, [page]);

  const formatMessages = (messages, userId) => {
    const result: Record<string, any> = {};
    messages.forEach((message) => {
      const isAuthor = message.user?._id === userId && message.adminId == null;
      const mappedMessage = {
        created: new Date(message.createdAt),
        content: message.text,
        position: isAuthor ? 'ltr' : 'rtl',
      };

      const formattedDate = moment(message.createdAt).format('L');
      if (result[formattedDate]) {
        result[formattedDate].messages.push(mappedMessage);
      } else {
        result[formattedDate] = { messages: [mappedMessage] };
      }
    });
    return Object.entries(result).map(([date, value]) => ({
      date,
      messages: value.messages,
    }));
  };

  const getMessages = async () => {
    try {
      const response = await request(`${endpoints.supportChats}/${params.id}`, {
        limit: pageSize,
        offset: page * pageSize,
      });
      response.data.messages.reverse();

      setUser(response.data.user)
      setMessages(response.data.messages);
      setTotal(response.data.messages.length);
      {/* @ts-ignore */}
      messagesScroller.current.scrollTop = messagesScroller.current.scrollHeight;
    } catch (e) {
      errorService.sendError(`Some error occurred ${e.message}, \n ${e.response?.data?.message}`);
    }
  };

  const onRowAdd = async (content) => {
    await ChatService.sendMessage({ chat: params.id, text: content });
    await getMessages();
  };
  const onEnterImages = async (files, content) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('content', content);
    formData.append('chat', params.id);
    await ChatService.sendFilesMessage(formData);
    await getMessages();
  };

  const mappedMessages = formatMessages(messages, user?._id);

  return (
    <div>
      <GridContainer>
      {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Чат {params.id}</p>
            </CardHeader>
            <CardBody className={classes.container}>
              <h1 className={classes.title}>Чат с {user?.email}</h1>
              <div className={classes.messagesScroll} ref={messagesScroller}>
                <div className={classes.messagesContainer}>
                  <div className={cn(classes.signal, 'signal')} />
                  {mappedMessages.map((group) => {
                    return (
                      <>
      {/* @ts-ignore */}
                        <MessageItem created={group.created} position="center">
                          {group.date}
                        </MessageItem>
                        {group.messages.map((msg) => (
                          <MessageItem
                            key={msg._id}
                            files={msg.files}
                            position={msg.position}
                            created={msg.created}
                          >
                            {msg.content}
                          </MessageItem>
                        ))}
                      </>
                    );
                  })}
                </div>
              </div>
              <TextArea onEnter={onRowAdd} onEnterImages={onEnterImages} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
