import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import cn from 'classnames';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Select, MenuItem } from '@material-ui/core';

import { errorService } from 'services/alert/services';

import { externalGameProviderTypes } from '../ExternalGames/constant';

import { dialogStyles } from './styles';

const CreateOrUpdateGameProviderModal = ({ open, handleClose, mode, data, onCreate, onUpdate }) => {
  const styles = dialogStyles();

  const handleSubmit = async (values) => {
    try {
      if (mode === 'create') await onCreate(values);
      else await onUpdate(data._id, values);
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      handleClose();
    }
  };

  const initialValues = useMemo(() => {
    if (mode === 'create') {
      return {
        name: '',
        parentProvider: 'algNet',
        externalId: '',
      };
    }
    return {
      name: data.name,
      externalId: data.externalId,
      parentProvider: data.parentProvider,
    };
  }, [mode, data]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle>{mode === 'create' ? 'Создать' : 'Обновить'} провайдер</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Введите название'),
            })}
            validateOnChange
            onSubmit={handleSubmit}
            render={({ handleSubmit, handleChange, handleBlur, touched, values, errors }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.name && touched.name)}
                      name="name"
                      margin="dense"
                      label="Название"
                      value={values.name}
                      placeholder="Введите название"
                    />
                    <div className={styles.errorText}>
                      {touched.name ? (errors.name as string) : ''}
                    </div>
                  </FormControl>
                  <FormControl
                    className={cn(styles.formControl, styles.selectFormControl)}
                    size="small"
                  >
                    <Select
                      value={values.parentProvider}
                      label="Тип провайдера"
                      name="parentProvider"
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.entries(externalGameProviderTypes).map(([value, label]) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.externalId && touched.externalId)}
                      name="externalId"
                      margin="dense"
                      label="Провайдер игры"
                      value={values.externalId}
                      placeholder="Введите ID провайдера"
                    />
                    <div className={styles.errorText}>
                      {touched.externalId ? (errors.externalId as string) : ''}
                    </div>
                  </FormControl>
                  <DialogActions>
                    <Button color="primary" variant="contained" type="submit">
                      {mode === 'create' ? 'Создать' : 'Обновить'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateOrUpdateGameProviderModal;
