export enum ETournamentType {
    PLAY_OFF = 1,
    PLAY_OFF_LONG = 2,
    LEAGUE = 3,
  }

export enum ETournamentStatus {
  REGISTERING = 1,
  COMPLETED = 2,
  RUNNING = 3,
  PREPARING = 6,
  CANCELLED = 4,
  FINISHED = 5,
}